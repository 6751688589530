import 'scss/pages/ocelot.scss';
import React from 'react';
import { Trans } from '@lingui/macro';
import Layout from 'components/Layout';
import Seo from 'components/SEO';
import Image from 'components/atoms/Image/Image';
import SecurityPolicy from '../components/Carousel/SecurityPolicy/SecurityPolicy';

const Ocelot = () => {
  return (
    <Layout className="ocelot-page">
      <Seo title="Ocelot" />
      <section className="hero-wrapper">
        <div className="hero">
          <div className="hero__content">
            <h1 className="hero__title">
              <Trans>Ocelot</Trans>
            </h1>
            <p className="hero__text">
              <Trans>
                Business-Driven Authorization Engine for Cloud-Native
                Applications.
              </Trans>
            </p>
          </div>
          <Image name="ocelot-page/ocelot-hero" className="hero__image" />
        </div>
      </section>

      <section className="in-control container">
        <h2 className="in-control__title">
          <Trans>You are in control</Trans>
        </h2>
        <p className="in-control__text">
          <Trans>
            Through Ocelot you can define which services should be involved in
            each specific business operations, which users are allowed to
            perform these actions as well as which roles, attributes or security
            levels are required.
          </Trans>
        </p>

        <div className="in-control-acb content-blocks content-blocks--image-background">
          <div className="content-block">
            <Image
              name="ocelot-page/security"
              className="content-block__image"
            />
            <div className="content-block__info">
              <h3 className="content-block__title color-primary">
                <Trans>Protect your services from the very beginning</Trans>
              </h3>
              <p>
                <Trans>
                  <strong>
                    Ocelot assigns identities to your workloads as soon as your
                    first commits are merged to your main branch
                  </strong>
                  .
                </Trans>
              </p>
              <p>
                <Trans>
                  Even before your environments have any running code, Ocelot
                  knows about the services your team are developing based on the
                  definitions they commit to their source repository. This
                  ensures that each single service has segregated identities
                  associated with specific roles and permissions even before
                  those services are actually deployed to the different
                  production and non-production environments.
                </Trans>
              </p>
            </div>
          </div>
          <div className="content-block">
            <Image
              name="ocelot-page/business-driven"
              className="content-block__image"
            />
            <div className="content-block__info">
              <h3 className="content-block__title color-primary">
                <Trans>Business-Driven Authorization</Trans>
              </h3>
              <p>
                <Trans>
                  <strong>
                    Take a business-centric approach to security by making
                    Ocelot aware of your business operations
                  </strong>
                  .
                </Trans>
              </p>
              <p>
                <Trans>
                  Define specific policies describing how services collaborate
                  with each other in order to complete a specific business
                  operation. Specify which role and/or permission is required to
                  trigger such operations and let Ocelot enforce these rules.
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="busines-driven container">
        <h2 className="busines-driven__title">
          <Trans>Business-driven approach to Service Mesh Security</Trans>
        </h2>

        <p className="busines-driven__text">
          <Trans>
            Ocelot adopts a business-driven approach to securing your service mesh.
          </Trans>
        </p>
        <p className="busines-driven__text">
          <Trans>
            Through Ocelot, the team developing a specific service is able to define the security policy governing its behavior.
            They do so by <strong>defining these policies in code</strong> and committing them to their source control system together
            with the service code.
          </Trans>
        </p>
        <p className="busines-driven__text">
          <Trans>
            This approach ensures that <strong>security is built-in</strong> instead of bolt-on as an after-thought, shifting left
            the implementation of defense mechanisms, which can now happen while features are developed into the application.
          </Trans>
        </p>
        <p className="busines-driven__text">
          <Trans>
            Auditors and Security Staff will be able to analyze all policies for every single service deployed in
            the mesh in a single application, the Ocelot Portal. The Ocelot Portal shows <strong>constantly up-to-date information</strong>,
            without requiring the team to keep their documentation up to date manually.
          </Trans>
        </p>
        <p className="busines-driven__text">
          <Trans>
            The authorization-as-code approach ensures that teams can only impact services they own. Developers
            are not be able to change the code of services and systems owned by other teams,
            so they will not be able to modify their security policies. Moreover, this also
            ensures a <strong>quick and transparent adoption</strong>, not requiring the team to learn and use new tools.
          </Trans>
        </p>
      </section>

      <section className="security-policy">
        <h2 className="security-policy__title">
          <Trans>
            Define a distributed security policy in the easiest and most
            effective way
          </Trans>
        </h2>
        <SecurityPolicy />
      </section>

      <section className="grained-control">
        <h2 className="grained-control__title">
          <Trans>Fine-grained Control</Trans>
        </h2>

        <div className="control-list">
          <div className="control-item">
            <Image name="ocelot-page/open-id" className="control-item__icon" />
            <h3 className="control-item__title">
              <Trans>Open ID Connect Support</Trans>
            </h3>
            <p className="control-item__text">
              <Trans>
                Ocelot integrates with any Open ID Connect Identity provider, so
                that you do not need to copy paste your current setup anywhere.
              </Trans>
            </p>
          </div>
          <div className="control-item">
            <Image
              name="ocelot-page/dynamic-session"
              className="control-item__icon"
            />
            <h3 className="control-item__title">
              <Trans>Dynamic Session Security</Trans>
            </h3>
            <p className="control-item__text">
              <Trans>
                Ocelot supports dynamic session security level that changes
                through its lifetime based on security events happening
                throughout the IT landscape.
              </Trans>
            </p>
          </div>
          <div className="control-item">
            <Image
              name="ocelot-page/custom-scope"
              className="control-item__icon"
            />
            <h3 className="control-item__title">
              <Trans>Custom scopes and roles</Trans>
            </h3>
            <p className="control-item__text">
              <Trans>
                Ocelot can fit your business perfectly thanks to the ability of
                defining custom scopes and roles.
              </Trans>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Ocelot;
