// import "./SecurityPolicy.scss";
import React from 'react';
import { Trans } from '@lingui/macro';
import uniqid from 'uniqid';
import easiest from './images/easiest.svg';
import mostEffective from './images/mostEffective.svg';
import Carousel from '../Carousel';

const SecurityPolicy = () => {
  const carouselSettings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    dotsClass: 'custom-dot-list',
    customPaging: (i) => (
      <button className="custom-dot">{`${carouselData[i].headerName}`}</button>
    ),
  };

  const carouselData = [
    {
      headerName: 'EASIEST',
      image: easiest,
      text: (
        <div>
          <p>
            <Trans>
              The Developers know best how their own services should behave.
            </Trans>
          </p>
          <p>
            <Trans>
              Ocelot enables them to{' '}
              <strong>
                define and maintain their services’ security policies
              </strong>{' '}
              without having to learn or even get access to bespoke solutions
              and/or tools; everything works through git.
            </Trans>
          </p>
          <p>
            <Trans>
              Security policies are then validated, combined, and optimized for
              runtime in a centralized highly available Ocelot cluster. The
              latter is then invoked for business operation authorization, token
              generation, certificate emission, and so on.{' '}
            </Trans>
          </p>
          <p>
            <Trans>
              Moreover, through its web application, you can easily get an
              overview of the current security policy across your entire IT
              landscape and use that for <strong>audit</strong> and{' '}
              <strong>fully-automated documentation</strong>.
            </Trans>
          </p>
        </div>
      ),
    },
    {
      headerName: 'MOST EFFECTIVE',
      image: mostEffective,
      text: (
        <div>
          <p>
            <Trans>
              Ocelot removes the need of learning any new technology. This
              enables the dev teams to{' '}
              <strong>focus on delivering value to your customers</strong> by
              using the tools they love.{' '}
            </Trans>
          </p>
          <p>
            <Trans>
              The security policy for a specific service resides with the code
              for that specific service and it is subject to any review process
              already in place for source code.
            </Trans>{' '}
          </p>
          <p>
            <Trans>
              In this way the developers have access exclusively to those parts
              of the security policy that{' '}
              <strong>directly relates to their own service</strong> and nothing
              else; they do not even have the need to access centralised
              security components, to manually perform changes, or worse to have
              a centralised security team wasting their time supporting every
              single change required by every single application team.
            </Trans>
          </p>
        </div>
      ),
    },
  ];

  return (
    <div className="security-policy-carousel-wrapper">
      <Carousel settings={carouselSettings}>
        {carouselData.map((slide) => (
          <div
            key={uniqid('security-policy-')}
            className="security-policy__slide"
          >
            <div className="security-policy__slide-text">{slide.text}</div>
            <img src={slide.image} alt="" className="security-policy__image" />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default SecurityPolicy;
